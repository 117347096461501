<template>
  <v-tooltip :color="$vuetify.theme.dark ? '#303030' : '#efefef'" :open-on-click="$vuetify.breakpoint.smAndDown" :bottom="!position || position === 'bottom'" :right="position === 'right'" :left="position === 'left'" :top="position === 'top'">
    <template v-slot:activator="{ on, attrs }">
      <template v-if="slotContent">
        <div class="inlineBlock" v-bind="attrs" v-on="on" :class="btnClasses">
          <slot></slot>
        </div>
      </template>
      <v-btn v-else :small="small" :x-small="x_small" icon :class="btnClasses">
        <v-icon :small="x_small" :style="opacity ? `opacity: ${opacity} !important;` : hover ? `opacity: .25 !important;` : ''" :class="[hover ? 'hover' : '', iconClasses ? iconClasses : 'grey--text']" v-bind="attrs" v-on="on"
        >
          mdi-{{icon ? icon : 'information-slab-symbol'}}
        </v-icon>
      </v-btn>
    </template>
    <div style="max-width:275px; min-width:125px" :class="$vuetify.theme.dark ? '' : 'black--text'">
      <b>{{title}}</b>
      <br v-if="title">
      <span v-html="text" :class="title ? 'grey--text' : ''"></span>
    </div>
  </v-tooltip>
</template>

<script>
  export default {
    props: [
      'title', 'text', 'icon', 'iconClasses', 'position', 'opacity', 'btnClasses', 'hover',
      'x_small', 'small', 'medium', 'large', 'x_large',
    ],
    computed: {
      slotContent() {
        // Capture the slot's inner HTML as a string
        //return this.$slots.default?.map(node => node.text || '').join('') || '';
        return !!this.$slots.default; // Returns true if slot has content
      }
    }
  }
</script>

<style scoped>
  .hover:hover { opacity: 1 !important;}

  .v-tooltip__content.menuable__content__active {
    opacity: 1 !important;
  }
</style>